// Recidiviz - a data platform for criminal justice reform
// Copyright (C) 2021 Recidiviz, Inc.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
// =============================================================================

import React from "react";
import { colors } from "../UiLibrary";

type BrandMarkProps = {
  light?: boolean;
};

export default function BrandMark({
  light,
}: BrandMarkProps): React.ReactElement {
  return (
    <svg
      aria-label="Spotlight"
      fill="none"
      // slight height differential from viewBox aligns with 16px text baseline
      height="33"
      preserveAspectRatio="xMidYMin meet"
      viewBox="0 0 99 28"
      width="99"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* wordmark */}
      <g fill={light ? colors.textLight : colors.text}>
        <path d="M30.5252 22.3947C29.6683 22.3947 28.9053 22.2476 28.2362 21.9535C27.5671 21.6594 27.0389 21.2594 26.6515 20.7535C26.2641 20.2358 26.047 19.6593 26 19.024H28.9757C29.0109 19.3652 29.1694 19.6417 29.4511 19.8534C29.7328 20.0652 30.0791 20.1711 30.49 20.1711C30.8656 20.1711 31.1532 20.1005 31.3527 19.9593C31.564 19.8064 31.6697 19.6123 31.6697 19.377C31.6697 19.0946 31.5229 18.8887 31.2295 18.7593C30.936 18.6181 30.4606 18.4652 29.8033 18.3005C29.099 18.1357 28.512 17.9651 28.0425 17.7887C27.573 17.6004 27.168 17.3122 26.8276 16.9239C26.4871 16.5239 26.3169 15.9886 26.3169 15.318C26.3169 14.7533 26.4695 14.2415 26.7747 13.7827C27.0917 13.3121 27.5495 12.9415 28.1481 12.6709C28.7585 12.4003 29.4804 12.265 30.3139 12.265C31.5464 12.265 32.5148 12.5709 33.2191 13.1827C33.9352 13.7944 34.346 14.6062 34.4517 15.618H31.6697C31.6227 15.2768 31.4701 15.0062 31.2119 14.8062C30.9654 14.6062 30.6367 14.5062 30.2258 14.5062C29.8737 14.5062 29.6037 14.5768 29.4159 14.718C29.2281 14.8474 29.1342 15.0298 29.1342 15.2651C29.1342 15.5474 29.2809 15.7592 29.5744 15.9004C29.8796 16.0416 30.3491 16.1827 30.983 16.3239C31.7108 16.5122 32.3035 16.7004 32.7613 16.8886C33.2191 17.0651 33.6183 17.3592 33.9587 17.771C34.3108 18.171 34.4928 18.7122 34.5045 19.3946C34.5045 19.9711 34.3402 20.4888 34.0115 20.9476C33.6946 21.3947 33.2309 21.7476 32.6205 22.0064C32.0218 22.2653 31.3234 22.3947 30.5252 22.3947Z" />
        <path d="M38.213 13.8003C38.5064 13.3415 38.9114 12.9709 39.4279 12.6885C39.9444 12.4062 40.5489 12.265 41.2415 12.265C42.0515 12.265 42.7851 12.4709 43.4425 12.8826C44.0998 13.2944 44.6163 13.8827 44.9919 14.6474C45.3793 15.4121 45.573 16.3004 45.573 17.3122C45.573 18.324 45.3793 19.2181 44.9919 19.9946C44.6163 20.7593 44.0998 21.3535 43.4425 21.777C42.7851 22.1888 42.0515 22.3947 41.2415 22.3947C40.5607 22.3947 39.9561 22.2535 39.4279 21.9711C38.9114 21.6888 38.5064 21.3241 38.213 20.877V26.9478H35.2021V12.4062H38.213V13.8003ZM42.5093 17.3122C42.5093 16.5592 42.298 15.971 41.8754 15.5474C41.4645 15.1121 40.9539 14.8945 40.3435 14.8945C39.7449 14.8945 39.2342 15.1121 38.8116 15.5474C38.4008 15.9827 38.1954 16.5769 38.1954 17.3298C38.1954 18.0828 38.4008 18.6769 38.8116 19.1122C39.2342 19.5475 39.7449 19.7652 40.3435 19.7652C40.9422 19.7652 41.4528 19.5475 41.8754 19.1122C42.298 18.6652 42.5093 18.0651 42.5093 17.3122Z" />
        <path d="M50.9206 22.3947C49.9581 22.3947 49.0894 22.1888 48.3147 21.777C47.5517 21.3652 46.9472 20.777 46.5011 20.0123C46.0668 19.2475 45.8496 18.3534 45.8496 17.3298C45.8496 16.318 46.0727 15.4298 46.5187 14.665C46.9648 13.8886 47.5752 13.2944 48.3499 12.8826C49.1246 12.4709 49.9933 12.265 50.9558 12.265C51.9184 12.265 52.787 12.4709 53.5618 12.8826C54.3365 13.2944 54.9469 13.8886 55.393 14.665C55.839 15.4298 56.0621 16.318 56.0621 17.3298C56.0621 18.3416 55.8332 19.2358 55.3754 20.0123C54.9293 20.777 54.313 21.3652 53.5266 21.777C52.7518 22.1888 51.8832 22.3947 50.9206 22.3947ZM50.9206 19.7828C51.4958 19.7828 51.983 19.5711 52.3821 19.1475C52.7929 18.724 52.9983 18.1181 52.9983 17.3298C52.9983 16.5416 52.7988 15.9357 52.3997 15.5121C52.0123 15.0886 51.531 14.8768 50.9558 14.8768C50.3689 14.8768 49.8818 15.0886 49.4944 15.5121C49.107 15.9239 48.9134 16.5298 48.9134 17.3298C48.9134 18.1181 49.1012 18.724 49.4768 19.1475C49.8642 19.5711 50.3454 19.7828 50.9206 19.7828Z" />
        <path d="M62.9637 19.6946V22.2535H60.459C59.3674 22.2535 58.5163 21.9888 57.9059 21.4594C57.2955 20.9182 56.9903 20.0417 56.9903 18.8299V14.9121H55.793V12.4062H56.9903V10.0061H60.0012V12.4062H61.9733V14.9121H60.0012V18.8652C60.0012 19.1593 60.0717 19.3711 60.2125 19.5005C60.3534 19.6299 60.5882 19.6946 60.9168 19.6946H62.9637Z" />
        <path d="M65.9746 9.1943V22.2535H62.9637V9.1943H65.9746Z" />
        <path d="M70.4632 12.9711V22.2535H67.378V12.9711H70.4632Z" />
        <path d="M75.6737 12.265C76.3663 12.265 76.9708 12.4062 77.4873 12.6885C78.0155 12.9709 78.4205 13.3415 78.7022 13.8003V12.4062H81.7131V22.2359C81.7131 23.1418 81.5312 23.9594 81.1673 24.6889C80.8152 25.4301 80.2693 26.0183 79.5298 26.4536C78.802 26.8889 77.8923 27.1066 76.8006 27.1066C75.345 27.1066 74.1653 26.7595 73.2615 26.0654C72.3576 25.383 71.8411 24.4536 71.712 23.2771H74.6877C74.7816 23.6536 75.0046 23.9477 75.3568 24.1594C75.7089 24.383 76.1432 24.4948 76.6597 24.4948C77.2819 24.4948 77.7749 24.3124 78.1388 23.9477C78.5144 23.5947 78.7022 23.0241 78.7022 22.2359V20.8417C78.4088 21.3005 78.0038 21.677 77.4873 21.9711C76.9708 22.2535 76.3663 22.3947 75.6737 22.3947C74.8638 22.3947 74.1301 22.1888 73.4728 21.777C72.8154 21.3535 72.293 20.7593 71.9057 19.9946C71.53 19.2181 71.3422 18.324 71.3422 17.3122C71.3422 16.3004 71.53 15.4121 71.9057 14.6474C72.293 13.8827 72.8154 13.2944 73.4728 12.8826C74.1301 12.4709 74.8638 12.265 75.6737 12.265ZM78.7022 17.3298C78.7022 16.5769 78.4909 15.9827 78.0684 15.5474C77.6575 15.1121 77.1528 14.8945 76.5541 14.8945C75.9554 14.8945 75.4448 15.1121 75.0222 15.5474C74.6114 15.971 74.406 16.5592 74.406 17.3122C74.406 18.0651 74.6114 18.6652 75.0222 19.1122C75.4448 19.5475 75.9554 19.7652 76.5541 19.7652C77.1528 19.7652 77.6575 19.5475 78.0684 19.1122C78.4909 18.6769 78.7022 18.0828 78.7022 17.3298Z" />
        <path d="M88.7863 12.3003C89.9132 12.3003 90.8171 12.6768 91.4979 13.4297C92.1787 14.1709 92.5192 15.1945 92.5192 16.5004V22.2535H89.5259V16.9063C89.5259 16.2474 89.3557 15.7357 89.0152 15.371C88.6748 15.0062 88.217 14.8239 87.6418 14.8239C87.0667 14.8239 86.6089 15.0062 86.2684 15.371C85.928 15.7357 85.7578 16.2474 85.7578 16.9063V22.2535H82.7469V9.1943H85.7578V13.7297C86.063 13.2944 86.4797 12.9474 87.008 12.6885C87.5362 12.4297 88.129 12.3003 88.7863 12.3003Z" />
        <path d="M98.9429 19.6946V22.2535H97.4111C96.3194 22.2535 95.4684 21.9888 94.858 21.4594C94.2476 20.9182 93.9424 20.0417 93.9424 18.8299V14.9121H92.745V12.4062H93.9424V10.0061H96.9533V12.4062H98.9253V14.9121H96.9533V18.8652C96.9533 19.1593 97.0237 19.3711 97.1646 19.5005C97.3054 19.6299 97.5402 19.6946 97.8689 19.6946H98.9429Z" />
        <ellipse cx="68.9185" cy="10.5442" rx="1.54067" ry="1.54416" />
      </g>
      {/* icon */}
      <g>
        <rect y="11" width="11" height="11" fill="#4693BE" />
        <rect
          x="10.1538"
          width="11.8462"
          height="11.8462"
          rx="5.92308"
          fill="#C53B3E"
        />
      </g>
    </svg>
  );
}
