// Recidiviz - a data platform for criminal justice reform
// Copyright (C) 2021 Recidiviz, Inc.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
// =============================================================================

import { Body16 } from "@recidiviz/design-system";
import styled from "styled-components/macro";
import colors from "./colors";
import { dynamicTextClass } from "./dynamicText";

export default styled(Body16)`
  /* footnotes */
  sup {
    font-size: 0.6em;
    vertical-align: super;
  }
  aside {
    font-size: 0.7em;
    line-height: 1.5;
    margin-top: 1.4em;
  }

  .${dynamicTextClass} {
    color: ${colors.dynamicText};
    font-weight: 700;
  }
`;
