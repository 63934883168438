// Recidiviz - a data platform for criminal justice reform
// Copyright (C) 2021 Recidiviz, Inc.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
// =============================================================================

import type { Topology } from "topojson-specification";

const topology: Topology = {
  type: "Topology",
  transform: {
    scale: [0.0017672224458014198, 0.0012457881355124143],
    translate: [-104.04976375045509, 45.9351074658262],
  },
  arcs: [
    [
      [1147, 2305],
      [1, 154],
    ],
    [
      [1148, 2459],
      [224, 1],
      [73, 0],
    ],
    [
      [1445, 2460],
      [0, -225],
      [25, -139],
      [222, -1],
    ],
    [
      [1692, 2095],
      [0, -70],
    ],
    [
      [1692, 2025],
      [-443, 1],
      [0, 209],
      [-101, 1],
      [-1, 69],
    ],
    [
      [2707, 1326],
      [434, 0],
    ],
    [
      [3141, 1326],
      [0, -209],
    ],
    [
      [3141, 1117],
      [-434, 1],
    ],
    [
      [2707, 1118],
      [0, 208],
    ],
    [
      [2339, 560],
      [264, -1],
    ],
    [
      [2603, 559],
      [234, -1],
    ],
    [
      [2837, 558],
      [-1, -279],
    ],
    [
      [2836, 279],
      [-476, 0],
    ],
    [
      [2360, 279],
      [-22, 1],
      [1, 280],
    ],
    [
      [217, 1120],
      [321, -1],
    ],
    [
      [538, 1119],
      [38, 0],
      [0, -279],
    ],
    [
      [576, 840],
      [-112, -1],
      [-1, -281],
    ],
    [
      [463, 558],
      [-214, 0],
    ],
    [
      [249, 558],
      [0, 281],
      [-32, 0],
      [0, 281],
    ],
    [
      [3589, 1048],
      [144, -1],
    ],
    [
      [3733, 1047],
      [349, -1],
    ],
    [
      [4082, 1046],
      [5, -70],
      [4, -56],
      [-8, -57],
      [40, -78],
      [-14, -108],
      [1, -69],
      [-2, -44],
      [0, -1],
    ],
    [
      [4108, 563],
      [-15, -5],
      [-263, -1],
    ],
    [
      [3830, 557],
      [-227, 1],
    ],
    [
      [3603, 558],
      [0, 280],
      [-14, 210],
    ],
    [
      [3258, 1814],
      [220, 0],
    ],
    [
      [3478, 1814],
      [12, -140],
      [0, -280],
    ],
    [
      [3490, 1394],
      [-58, 0],
    ],
    [
      [3432, 1394],
      [-291, 0],
    ],
    [
      [3141, 1394],
      [-15, 0],
      [0, 140],
    ],
    [
      [3126, 1534],
      [0, 55],
    ],
    [
      [3126, 1589],
      [0, 85],
      [58, 0],
      [1, 140],
      [73, 0],
    ],
    [
      [2227, 1118],
      [45, 0],
    ],
    [
      [2272, 1118],
      [313, 0],
    ],
    [
      [2585, 1118],
      [20, -278],
      [-2, -281],
    ],
    [
      [2339, 560],
      [-94, 1],
    ],
    [
      [2245, 561],
      [4, 279],
      [-22, 278],
    ],
    [
      [3490, 1394],
      [231, 0],
    ],
    [
      [3721, 1394],
      [-1, -278],
      [13, -69],
    ],
    [
      [3589, 1048],
      [-143, 0],
    ],
    [
      [3446, 1048],
      [-15, 69],
      [1, 277],
    ],
    [
      [2689, 1534],
      [420, 1],
      [17, -1],
    ],
    [
      [3141, 1394],
      [0, -68],
    ],
    [
      [2707, 1326],
      [-18, 69],
      [0, 139],
    ],
    [
      [2858, 2460],
      [74, 0],
      [520, 1],
    ],
    [
      [3452, 2461],
      [0, -227],
      [13, -141],
    ],
    [
      [3465, 2093],
      [-221, 1],
    ],
    [
      [3244, 2094],
      [-370, 0],
    ],
    [
      [2874, 2094],
      [0, 140],
      [-16, 0],
      [0, 226],
    ],
    [
      [2188, 2460],
      [152, -1],
      [220, 1],
    ],
    [
      [2560, 2460],
      [0, -226],
      [18, 0],
      [0, -139],
    ],
    [
      [2578, 2095],
      [-369, 0],
    ],
    [
      [2209, 2095],
      [0, 139],
      [-21, 0],
      [0, 226],
    ],
    [
      [3465, 2093],
      [440, -2],
    ],
    [
      [3905, 2091],
      [0, -6],
      [5, -82],
      [1, -69],
      [5, -70],
      [-7, -51],
    ],
    [
      [3909, 1813],
      [-431, 1],
    ],
    [
      [3258, 1814],
      [-15, 140],
      [1, 140],
    ],
    [
      [576, 840],
      [286, 0],
      [216, 23],
    ],
    [
      [1078, 863],
      [27, -23],
    ],
    [
      [1105, 840],
      [0, -212],
    ],
    [
      [1105, 628],
      [0, -69],
    ],
    [
      [1105, 559],
      [-470, -1],
    ],
    [
      [635, 558],
      [-172, 0],
    ],
    [
      [3420, 279],
      [410, 0],
    ],
    [
      [3830, 279],
      [12, -8],
      [0, -221],
      [18, -50],
    ],
    [
      [3860, 0],
      [-178, 0],
      [-133, 0],
      [-113, 1],
      [-17, 0],
    ],
    [
      [3419, 1],
      [1, 278],
    ],
    [
      [1105, 559],
      [26, 0],
      [2, -279],
      [28, -63],
    ],
    [
      [1161, 217],
      [-283, 1],
      [0, 62],
      [-241, -2],
    ],
    [
      [637, 278],
      [-2, 280],
    ],
    [
      [2, 277],
      [595, 1],
    ],
    [
      [597, 278],
      [0, -270],
    ],
    [
      [597, 8],
      [-126, 0],
      [-123, 0],
      [-128, 0],
      [-218, 0],
      [0, 269],
    ],
    [
      [3159, 1048],
      [287, 0],
    ],
    [
      [3603, 558],
      [-198, 1],
    ],
    [
      [3405, 559],
      [-230, 0],
    ],
    [
      [3175, 559],
      [0, 280],
      [-16, 209],
    ],
    [
      [1746, 981],
      [122, 0],
      [0, 137],
      [43, 0],
    ],
    [
      [1911, 1118],
      [316, 0],
    ],
    [
      [2245, 561],
      [-328, 1],
    ],
    [
      [1917, 562],
      [-71, 45],
      [5, 37],
      [-73, 134],
      [-16, 63],
    ],
    [
      [1762, 841],
      [32, 32],
      [-48, 108],
    ],
    [
      [1692, 2095],
      [0, 70],
      [370, 0],
      [0, -70],
      [73, 0],
    ],
    [
      [2135, 2095],
      [0, -140],
      [22, -280],
      [22, 0],
      [0, -140],
    ],
    [
      [2179, 1535],
      [-218, 0],
    ],
    [
      [1961, 1535],
      [-219, 1],
    ],
    [
      [1742, 1536],
      [-25, 139],
      [1, 280],
      [-26, 70],
    ],
    [
      [2578, 1955],
      [166, 0],
    ],
    [
      [2744, 1955],
      [0, -173],
      [74, -38],
      [0, -69],
      [39, 25],
      [5, -51],
      [107, 56],
      [30, -62],
      [-25, -25],
      [69, 39],
      [27, -67],
      [56, -1],
    ],
    [
      [2689, 1534],
      [-291, 1],
    ],
    [
      [2398, 1535],
      [-21, 139],
      [1, 281],
      [200, 0],
    ],
    [
      [3, 486],
      [138, 0],
      [0, 72],
      [108, 0],
    ],
    [
      [637, 278],
      [-40, 0],
    ],
    [
      [2, 277],
      [0, 36],
      [1, 148],
      [0, 25],
    ],
    [
      [2560, 2460],
      [85, 0],
      [213, 0],
    ],
    [
      [2874, 2094],
      [0, -139],
      [-130, 0],
    ],
    [
      [2578, 1955],
      [0, 140],
    ],
    [
      [1, 2460],
      [381, -1],
      [247, 1],
    ],
    [
      [629, 2460],
      [-1, -224],
      [31, 0],
      [0, -70],
    ],
    [
      [659, 2166],
      [-658, 0],
    ],
    [
      [1, 2166],
      [-1, 172],
      [1, 122],
    ],
    [
      [2252, 1535],
      [146, 0],
    ],
    [
      [2707, 1118],
      [-122, 0],
    ],
    [
      [2272, 1118],
      [1, 277],
      [-21, 140],
    ],
    [
      [1043, 1315],
      [96, -1],
      [126, -61],
      [60, 33],
      [38, -8],
      [121, 27],
      [32, -34],
      [-39, -34],
      [44, -86],
      [9, -61],
      [50, -22],
    ],
    [
      [1580, 1068],
      [0, -19],
      [-287, 0],
      [0, -209],
    ],
    [
      [1293, 840],
      [-188, 0],
    ],
    [
      [1078, 863],
      [0, 256],
      [-35, 0],
      [0, 196],
    ],
    [
      [3830, 557],
      [0, -278],
    ],
    [
      [3420, 279],
      [-16, 0],
    ],
    [
      [3404, 279],
      [1, 280],
    ],
    [
      [2837, 558],
      [338, 1],
    ],
    [
      [3404, 279],
      [-548, 0],
    ],
    [
      [2856, 279],
      [-20, 0],
    ],
    [
      [1105, 628],
      [214, 1],
      [0, -70],
      [238, -1],
      [0, -208],
      [141, 0],
      [13, -70],
    ],
    [
      [1711, 280],
      [-97, -80],
      [5, -28],
      [-64, -13],
      [1, -23],
      [-144, -78],
      [-39, 24],
      [-23, -21],
      [-49, 25],
      [-50, -7],
      [-6, 32],
      [-84, -16],
    ],
    [
      [1161, 95],
      [0, 122],
    ],
    [
      [2179, 1535],
      [73, 0],
    ],
    [
      [1911, 1118],
      [0, 277],
      [50, 0],
      [0, 140],
    ],
    [
      [3141, 1117],
      [18, -1],
      [0, -68],
    ],
    [
      [3909, 1813],
      [-3, -17],
      [0, -3],
      [23, -62],
      [21, -53],
      [18, -74],
      [23, -72],
      [38, -80],
      [20, -58],
    ],
    [
      [4049, 1394],
      [-328, 0],
    ],
    [
      [2856, 279],
      [-2, -275],
    ],
    [
      [2854, 4],
      [-49, 0],
      [-143, 0],
      [-82, 0],
      [-69, 1],
      [-60, 0],
      [0, 0],
      [-92, 0],
    ],
    [
      [2359, 5],
      [1, 274],
    ],
    [
      [629, 2460],
      [50, 0],
      [358, -1],
      [111, 0],
    ],
    [
      [1147, 2305],
      [-73, 0],
      [-1, -69],
      [-46, 0],
      [0, -140],
    ],
    [
      [1027, 2096],
      [-368, 0],
    ],
    [
      [659, 2096],
      [0, 70],
    ],
    [
      [1445, 2460],
      [210, -1],
      [393, 1],
      [140, 0],
    ],
    [
      [2209, 2095],
      [-74, 0],
    ],
    [
      [1232, 1535],
      [510, 1],
    ],
    [
      [1746, 981],
      [-7, 75],
      [-33, 29],
      [-126, -17],
    ],
    [
      [1043, 1315],
      [-34, 40],
      [2, 127],
      [-41, 15],
      [-29, -34],
    ],
    [
      [941, 1463],
      [0, 72],
      [291, 0],
    ],
    [
      [3419, 1],
      [-35, 0],
      [-195, 0],
      [-119, 2],
      [-56, 0],
      [-160, 1],
      [0, 0],
    ],
    [
      [659, 2096],
      [0, -140],
      [33, 1],
      [0, -201],
    ],
    [
      [692, 1756],
      [-158, 19],
      [-54, -9],
      [-29, -34],
      [10, -25],
      [-141, -44],
      [-57, 38],
      [21, 27],
      [-28, 37],
      [-82, -33],
      [19, -22],
      [-43, -5],
      [-9, -52],
      [-31, 16],
      [-36, -47],
      [-71, 32],
    ],
    [
      [3, 1654],
      [-1, 197],
      [0, 119],
      [-1, 84],
      [0, 112],
    ],
    [
      [4049, 1394],
      [7, -19],
      [16, -62],
      [-1, -58],
      [-1, -46],
      [9, -131],
      [3, -32],
    ],
    [
      [3, 1174],
      [0, 165],
      [1, 160],
      [-1, 135],
      [0, 20],
    ],
    [
      [692, 1756],
      [92, -37],
      [63, -64],
      [-52, -73],
      [1, -66],
    ],
    [
      [796, 1516],
      [0, -121],
      [-258, 1],
      [0, -277],
    ],
    [
      [217, 1120],
      [-214, 0],
    ],
    [
      [3, 1120],
      [0, 2],
      [0, 52],
    ],
    [
      [1762, 841],
      [-469, -1],
    ],
    [
      [796, 1516],
      [45, -35],
      [100, -18],
    ],
    [
      [1917, 562],
      [58, -38],
      [-17, -128],
    ],
    [
      [1958, 396],
      [-74, -31],
      [-93, 16],
      [-80, -101],
    ],
    [
      [3, 486],
      [-1, 81],
      [0, 58],
      [0, 177],
      [1, 155],
      [0, 163],
    ],
    [
      [1958, 396],
      [22, -52],
      [-4, -91],
      [-40, -84],
      [8, -59],
      [58, -103],
    ],
    [
      [2002, 7],
      [-141, 0],
      [-196, 0],
      [-146, 0],
      [-109, 0],
      [-134, 0],
      [-116, 1],
    ],
    [
      [1160, 8],
      [1, 87],
    ],
    [
      [1160, 8],
      [-1, 0],
      [-49, 0],
      [-136, 0],
      [-126, 0],
      [-87, 0],
      [-99, 0],
      [-35, 0],
      [-30, 0],
    ],
    [
      [1027, 2096],
      [148, 1],
      [0, -141],
      [29, 0],
      [0, -280],
      [28, 0],
      [0, -141],
    ],
    [
      [4108, 563],
      [2, -5],
      [24, -51],
      [20, -105],
      [35, -62],
      [26, -31],
      [3, -80],
      [23, -109],
      [-10, -50],
      [-1, -5],
      [6, -65],
      [-293, 1],
      [-83, -1],
    ],
    [
      [3452, 2461],
      [98, 0],
      [309, 0],
      [1, -44],
      [23, -63],
      [20, -76],
      [17, -48],
      [12, -36],
      [-24, -68],
      [-3, -32],
      [0, -3],
    ],
    [
      [2359, 5],
      [0, 0],
      [-154, 1],
      [-80, 1],
      [-116, 0],
      [-7, 0],
      [0, 0],
    ],
  ],
  objects: {
    program_regions: {
      type: "GeometryCollection",
      geometries: [
        {
          type: "MultiPolygon",
          arcs: [
            [
              [
                125,
                1,
                129,
                -53,
                -52,
                -97,
                -91,
                -102,
                -118,
                84,
                85,
                -132,
                -135,
                -147,
                -142,
                -137,
                128,
                -99,
              ],
            ],
          ],
          id: "2",
          properties: {
            STATEFP: "38",
            LSAD: "06",
          },
        },
        {
          type: "MultiPolygon",
          arcs: [
            [
              [
                -29,
                -41,
                -40,
                -25,
                74,
                -111,
                -110,
                -67,
                135,
                123,
                124,
                13,
                35,
                36,
                32,
                103,
                101,
                -90,
                -44,
                5,
                -43,
              ],
            ],
          ],
          id: "6",
          properties: {
            STATEFP: "38",
            LSAD: "06",
          },
        },
        {
          type: "MultiPolygon",
          arcs: [
            [
              [
                146,
                -134,
                -108,
                58,
                59,
                60,
                67,
                -117,
                -153,
                153,
                72,
                93,
                149,
                -144,
                14,
                -143,
              ],
            ],
          ],
          id: "8",
          properties: {
            STATEFP: "38",
            LSAD: "06",
          },
        },
        {
          type: "MultiPolygon",
          arcs: [
            [[-28, 37, -122, 139, 21, 155, 65, 66, 109, 110, -75, 24, 39, 40]],
          ],
          id: "5",
          properties: {
            STATEFP: "38",
            LSAD: "06",
          },
        },
        {
          type: "MultiPolygon",
          arcs: [[[156, 54, 120, 121, -38, 27, 28, 29, 30, 31, 56, -47, -46]]],
          id: "4",
          properties: {
            STATEFP: "38",
            LSAD: "06",
          },
        },
        {
          type: "MultiPolygon",
          arcs: [
            [
              [
                49,
                94,
                44,
                45,
                46,
                -57,
                -32,
                -31,
                -30,
                42,
                -6,
                43,
                89,
                90,
                96,
                51,
                52,
              ],
            ],
          ],
          id: "3",
          properties: {
            STATEFP: "38",
            LSAD: "06",
          },
        },
        {
          type: "MultiPolygon",
          arcs: [
            [
              [
                131,
                -86,
                -85,
                117,
                -104,
                -33,
                -37,
                -36,
                -14,
                -125,
                157,
                151,
                152,
                116,
                -68,
                -61,
                -60,
                -59,
                107,
                133,
                134,
              ],
            ],
          ],
          id: "7",
          properties: {
            STATEFP: "38",
            LSAD: "06",
          },
        },
        {
          type: "MultiPolygon",
          arcs: [[[140, 138, 100, 97, 98, -129, 136, 141, 142, -15, 143, 144]]],
          id: "1",
          properties: {
            STATEFP: "38",
            LSAD: "06",
          },
        },
      ],
    },
  },
};

export default topology;
