// Recidiviz - a data platform for criminal justice reform
// Copyright (C) 2022 Recidiviz, Inc.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
// =============================================================================

import { ValuesType } from "utility-types";
import { RawMetricData } from "./fetchMetrics";
import { RiderCohort } from "../demographics/types";
import { DemographicFields, RateFields } from "./types";
import { extractDemographicFields } from "./utils";

export type RateByCategoryAndDemographicsRecord = DemographicFields &
  RateFields & {
    category: RiderCohort;
  };

function createRateByCategoryAndDemographicsRecord(
  record: ValuesType<RawMetricData>
) {
  return {
    rate: Number(record.event_rate),
    rateNumerator: Number(record.events),
    rateDenominator: Number(record.cohort_size),
    category: record.cohort as RiderCohort,
    ...extractDemographicFields(record),
  };
}

export function riderRateByCategoryAndDemographics(
  rawRecords: RawMetricData
): RateByCategoryAndDemographicsRecord[] {
  return rawRecords.map(createRateByCategoryAndDemographicsRecord);
}
